<template>
  <v-dialog :value="viewConfig" width="1000" @input="close" scrollable>
    <v-card>
      <v-card-title class="headline">Configuration</v-card-title>
      <!-- <v-card-text> -->
      <p class="mb-0 pl-4 pt-4"><b>Revenue Recognition</b></p>
      <!-- </v-card-text> -->
      <v-card-text class="pa-4">
        <v-card rounded outlined class="pa-2 mt-0">
          <v-row>
            <v-col md="6" class="">
              <v-radio-group v-model="start_day" column>
                <v-radio
                  label="Start Day"
                  color="primary darken-3"
                  :value="0"
                ></v-radio>
              </v-radio-group>
              <v-select
                v-model="start_time"
                :items="startTimes"
                label="Start Time*"
                item-text="text"
                item-value="value"
                required
                outlined
                dense
                class="shadow-0 q-autocomplete"
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                :rules="[(v) => !!v || 'To time is required']"
                @change="dateChange($event, 'date')"
              ></v-select>
            </v-col>
            <v-col md="6" class="">
              <v-radio-group v-model="end_day" row>
                <v-radio
                  label="End Day"
                  color="primary darken-3"
                  :value="0"
                ></v-radio>
                <v-radio
                  label="Day + 1"
                  color="primary darken-3"
                  :value="1"
                ></v-radio>
              </v-radio-group>
              <v-select
                :items="startTimes"
                label="End Time*"
                item-text="text"
                item-value="value"
                v-model="end_time"
                required
                dense
                class="shadow-0 q-autocomplete"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                :rules="[(v) => !!v || 'To time is required']"
                @change="dateChange($event, 'date')"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-row>
        <v-col cols="4">
          <p class="mb-0 pl-4 pt-4"><b>Card Payment Settings</b></p>
          <!-- </v-card-text> -->
          <v-card-text class="">
            <v-card rounded outlined class="pa-4 mt-0">
              <v-row>
                <v-col md="12" class="">
                  <v-radio-group v-model="card_payment" row>
                    <v-radio
                      label="Simple"
                      color="primary darken-3"
                      :value="0"
                    ></v-radio>
                    <v-radio
                      label="Advanced"
                      color="primary darken-3"
                      :value="1"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <p class="mb-0 pt-4 pl-4"><b>Payment Method Settings</b></p>
          <!-- </v-card-text> -->
          <v-card-text class="">
            <v-card rounded outlined class="pa-4 mt-0">
              <v-row>
                <v-col md="12" class="">
                  <v-switch
                      v-model="enable_multi_payment"
                      label="Enable Multiple Payment Method"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <p class="mb-0 pt-4 pl-4"><b>Invoice Listing Settings</b></p>
          <!-- </v-card-text> -->
          <v-card-text class="">
            <v-card rounded outlined class="pa-4 mt-0">
              <v-row>
                <v-col md="12" class="">
                  <v-switch
                      v-model="hide_zero_invoice"
                      label="Hide 0 value invoice"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-col>
        <v-col cols="6">
          <p class="mb-0 pl-4 pt-2"><b>Order Mail Settings</b></p>
          <!-- </v-card-text> -->
          <v-card-text class="">
            <v-card rounded outlined class="pa-4 mt-0">
              <div class="d-flex flex-row align-items-center">
                <v-col md="6" class="">
                  <v-switch
                    v-model="enable_email"
                    label="Order confirmation mail"
                    hide-details="auto"
                  ></v-switch>
                </v-col>
                <v-col md="6" class="">
                  <v-switch
                    v-model="hide_customer_data"
                    label="Hide customer details in receipt"
                    hide-details="auto"
                  ></v-switch>
                </v-col>
              </div>
            </v-card>
          </v-card-text>
        </v-col>
        <v-col cols="6">
          <p class="mb-0 pl-4 pt-2"><b>Operator Email</b></p>
          <v-card-text class="">
            <v-card rounded outlined class="pa-4 mt-0">

                <v-text-field
                  outlined
                  class="mt-4 mb-4 shadow-0 q-text-field"
                  background-color="#fff"
                  v-model="operator_email"
                  label=""
                  dense
                  hide-details="auto"
                >
                  <v-tooltip slot="append" top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" dark>
                        mdi-information
                      </v-icon>
                    </template>
                    <span
                      >Email provided here will receive all the online sales
                      notifications and bcc in installment email.</span
                    >
                  </v-tooltip>
                </v-text-field>

            </v-card>
          </v-card-text>
        </v-col>
      </v-row>
      <p class="mb-0 pl-4 pt-0"><b>Receipt Policy</b></p>

      <v-card-text class="pa-4">
        <v-textarea
          outlined
          v-model="policyData"
          rows="5"
          name="input-7-4"
          value=""
          height="141px"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="blue-color" dark @click="close">Close</v-btn>
        <v-btn class="teal-color" @click="saveConfiguration" dark>Save</v-btn>
      </v-card-actions>
    </v-card>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  props: {
    viewConfig: { type: Boolean, default: false },
  },
  data() {
    return {
      startTimes: [],
      dateToggle: "DATE",
      start_time: "",
      end_time: "",
      timeIncrement: 60,
      start_day: 0,
      end_day: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      card_payment: null,
      enable_email: null,
      hide_customer_data: 0,
      enable_multi_payment: 1,
      hide_zero_invoice: 1,
      operator_email: "",
      deleted_codes: [],
      policyData: "",
    };
  },
  watch: {
    viewConfig: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSalesConfiguration();
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.getStartTimes();
    // this.getSalesConfiguration();
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  methods: {
    dateChange(data) {
      console.log(data);
    },
    close() {
      this.operator_email = "";
      this.$emit("close");
    },
    saveConfiguration() {
      if (this.start_time == "" || this.end_time == "") {
        this.showError("Start Time & End Time Required");
        return false;
      }
      if (this.end_day == 0) {
        if (this.start_time >= this.end_time) {
          this.showError("End Time should be greater than start time");
          return false;
        }
      }
      const formData = new FormData();
      formData.append("end_day", this.end_day);
      formData.append("end_time", this.end_time);
      formData.append("start_time", this.start_time);
      formData.append("card_payment", this.card_payment);
      formData.append("enable_email", this.enable_email);
      formData.append("enable_multi_payment", this.enable_multi_payment);
      formData.append("hide_zero_invoice", this.hide_zero_invoice);
      formData.append("operator_email", this.operator_email);
      formData.append("hide_customer_data", this.hide_customer_data);

      if (this.policyData) {
        formData.append("policy", this.policyData);
      }
      this.$http
        .post(`venues/configurations/sales`, formData)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Configuration Saved");
            this.close();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    confirmActions(data) {
      console.log(data);
      this.confirmModel.id = null;
    },
    getStartTimes() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.startTimes = [];
      this.startTimes.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.startTimes.push(data);
          hour = 24;
        } else {
          this.startTimes.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
    getSalesConfiguration() {
      this.$http
        .get(`venues/configurations/sales`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            if (data) {
              this.start_time = data.start_time;
              this.end_time = data.end_time;
              this.end_day = data.end_day;
              this.card_payment = data.card_payment ?? 1;
              this.enable_email = data.enable_email ?? 1;
              this.operator_email = data.operator_email ?? "";
              this.enable_multi_payment =
                data.enable_multi_payment !== undefined
                  ? data.enable_multi_payment
                  : 1;
              this.hide_zero_invoice =
                data.hide_zero_invoice !== undefined
                  ? data.hide_zero_invoice
                  : 1;
              if (response.data && response.data.policy) {
                this.policyData = response.data.policy;
              } else {
                this.policyData = "";
              }
              if (response.data && response.data.hide_customer_data) {
                this.hide_customer_data = response.data.hide_customer_data;
              } else {
                this.hide_customer_data = 0;
              }

              console.log(response.data);
              console.log(this.hide_customer_data);
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style></style>
